import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import preloader from '../images/preloader.svg'
import Banner from '../components/ads/banner'
const axios = require('axios').default;

function NtnStatus() {
    const [inputCNIC, setInputCNIC] = useState("");
    const [showNTN, setShowNTN] = useState(false);
    const [showNTNError, setShowNTNError] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [result, setResult] = useState({});
    const handleCheckCNIC = () => {
        setShowLoader(true);
        setShowNTNError(false);
        setShowNTN(false);
        axios.get('https://secure.befiler.com/befiler_services_prod/fbr/ntn/inquiry?param=' + inputCNIC)
            .then(function (response) {
                setShowLoader(false);
                if (response.data.code === 1) {
                    setResult(response.data.response[0]);
                    setShowNTN(true);
                }
                else if (response.data.code === 0) {
                    setShowNTNError(true);
                }
            })
            .catch(function (error) {
                setShowLoader(false);
                setShowNTNError(true);
            });
    }
    return (
        <Layout>
            <div className='row'>
                <div className='col-12 text-center mb-3'>
                    <h1>NTN STATUS CHECKER</h1>
                </div>
                <div className='col-12 col-md-6 mx-auto'>
                    <div className="input-group mb-3">
                        <input type="text" value={inputCNIC} onChange={e => setInputCNIC(e.target.value)} className="form-control cal-container m-0" placeholder="Enter your CNIC" aria-label="Enter your CNIC" aria-describedby="button-addon2" />
                        <button className="btn btn-danger" type="button" onClick={handleCheckCNIC} id="button-addon2">Check NTN Status</button>
                    </div>
                    {showLoader && (
                        <div className="text-center"><img src={preloader} alt="pre loader"></img></div>
                    )}
                    {showNTNError && (
                        <div className="text-danger">Record doesn't found! Please enter you valid CNIC number</div>
                    )}
                </div>
            </div>
            {showNTN && (
                <>
                    <div className='row mt-4 mb-3'>
                        <div className='col-12'>
                            <h4>Your NTN Details</h4>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6 mb-3">
                            <strong className="d-block">Registration No</strong>
                            <span>{result?.registrationNo}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Reference No</strong>
                            <span>{result?.referenceNo}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">STRN</strong>
                            <span>{result?.strn}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Name</strong>
                            <span>{result?.name}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Category</strong>
                            <span>{result?.category}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Registered On</strong>
                            <span>{result?.registeredOn}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Tax Office</strong>
                            <span>{result?.taxOffice}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <strong className="d-block">Registration Status</strong>
                            <span>{result?.registrationStatus}</span>
                        </div>
                        <div className="col-12">
                            <strong className="d-block">Address</strong>
                            <span>{result?.address}</span>
                        </div>
                    </div>
                </>
            )}
            <div className='row mb-4 print-hide'>
                <div className='col-12 text-center'>
                    <Banner></Banner>
                    </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <h4>NTN Profile Inquiry</h4>
                    <p><strong>Individuals only</strong>: This public service is only available to those individuals who are holding up a valid CNIC number.</p>
                    <p>What does reference number mean? Reference number is known as NTN. Every Pakistani taxpayer receives a National Tax Number, a distinctive identification.</p>
                    <p>You may also check your ATL Status here. Those who have an active ATL and a registered NTN might gain greatly from specific financial occurrences where the tax implications for non-filers in Pakistan are double those of filers.</p>
                    <p>People in developed societies pay taxes.</p>
                    <p>Even though your NTN is registered, you are not automatically a tax filer. Check the Filer and Non-Filer status as well. Every year on March 1st, FBR updates the ATL. <Link to="/atl-status/">Check Active Tax Payer Status Online</Link></p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h4>How Can I Register My NTN Number?</h4>
                    <p>Your NTN number can be registered very quickly. You can electronically register your NTN on the FBR IRIS portal if you are an individual. Making your own income tax registration can result in errors that will need to be corrected. Give us the duty of registering your NTN to streamline the procedure. Since 2007, we have been assisting our clients.</p>
                    <h4>Is NTN Number Same As CNIC Number?</h4>
                    <p>All Pakistani individual taxpayers will receive National Tax Numbers (NTNs), same as their NADRA-issued CNIC numbers, in accordance with a new SRO from the Federal Board of Revenue. People can utilise their CNICs instead of their NTN numbers to file income tax returns because CNIC numbers have now changed to NTN numbers. However, this only applies to individual taxpayers because company NTN numbers, which are made up of a 7-digit number, are issued by the FBR. Your CNIC number can be used to quickly obtain your NTN.</p>
                    <h4>What Is Filer And Non Filer?</h4>
                    <p>According to the Ordinance, a "filer" is a taxpayer whose name is listed on the Federal Board of Revenue's periodically updated list of active taxpayers. Additionally, the filer is a bearer of an NTN, commonly known as a taxpayer card or a tax registration number. A "non-filer," on the other hand, is someone who doesn't file tax returns in Pakistan.</p>
                    <h4>Documents Required For Individual NTN Registration:</h4>
                    <p>Things required for NTN registration of individuals includes:</p>
                    <ul>
                        <li>Original CNIC</li>
                        <li>Cell phone with registered SIM against own CNIC,</li>
                        <li>personal Email address,</li>
                        <li>Original certificate of maintenance of personal bank account as well as</li>
                        <li>Original paid utility bill.</li>
                        <li>If having a business, individuals are required to bring utility bill of business premises not older than 3 months as well as original evidence of ownership of business premises.</li>
                    </ul>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <h4>Benefits of having NTN</h4>
                    <ul>
                        <li><strong>Tax Saving:</strong> Get the most tax savings possible by claiming investment tax credits and adjusting taxes paid on utilities, vehicles, real estate transactions, and cash withdrawals, among other things.</li>
                        <li><strong>Lower Rate of Withholding Tax:</strong> Non-Filers pay higher rates of taxes on a variety of transactions, such as withholding tax on cash withdrawals and banking transfers that exceed Rs. 50,000.</li>
                        <li><strong>Avoid Penalities:</strong> Avoid fines and/or prosecutions from the FBR by acting responsibly and filing your return on time.</li>
                        <li><strong>Compliance Check:</strong> Today, many banks and government-affiliated entities verify that you have filed your FBR taxes before granting your requests for things like company registration, property acquisition, loan processing, etc.</li>
                    </ul>
                </div>
            </div>
        </Layout>
    );
}
export const Head = () => <Seo title="NTN Status Checker | Tax Calculator Pakistan" description="You can verify your NTN status by entering your CNIC in National Tax Number Verification Systems. Get your details about NTN status." />

export default NtnStatus;